import 'isomorphic-fetch';
import { createAction } from 'redux-actions';
import _get from 'lodash/get';

import { loginConfigs, messagingLanguage } from './login.data';

export const ATTEMPT_LOGIN_REQUEST = 'ATTEMPT_LOGIN_REQUEST';
export const ATTEMPT_LOGIN_SUCCESS = 'ATTEMPT_LOGIN_SUCCESS';
export const ATTEMPT_LOGIN_FAILURE = 'ATTEMPT_LOGIN_FAILURE';

export const attemptLoginRequest = createAction(ATTEMPT_LOGIN_REQUEST);
export const attemptLoginSuccess = createAction(ATTEMPT_LOGIN_SUCCESS);
export const attemptLoginFailure = createAction(ATTEMPT_LOGIN_FAILURE);

export const attemptLogin = (data = {}, destination = null) => (
  (dispatch) => {
    const url = destination === 'checkout' ? '/api/auth/_with-checkout' : '/api/auth';
    dispatch(attemptLoginRequest())
    return fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then((res) => {
        // If something goes wrong, throw the whole result object. That way we can
        // wait on the precise error message, which comes through in the body of
        // the response.
        if (!res.ok) throw res;

        return res.json();
      })
      .then((data) => {
        if (!data) throw Error(_get(messagingLanguage, `en-us.unknown-error`)); // This probably should never happen

        // If they're requesting a known destination (in our loginConfig), send the redirectURL
        // along. If not, it'll be null, and the app will know how to handle that.
        const destinationURL = _get(loginConfigs, `${destination}.redirectURL`);
        
        dispatch(
          attemptLoginSuccess(
            {
              message: 'Login successful',
              redirect: !!destinationURL,
              redirectURL: destinationURL,
              token: data.sessionToken
            }
          )
        );
      })
      .catch((error) => {
        const generalError = 'Something went wrong, please try again later.';

        if (error.json) {
          // Since most errors we expect will come through as a response object, we
          // need to wait for it to resolve before reading the reason why. See
          // https://developer.mozilla.org/en-US/docs/Web/API/Body/json for detail.
          error.json().then(errorObj => {
            console.log({errorObj});
            // By this point, the error object is just a regular JS object, and `statusText`
            // is what we set in the Stargate API (see: ../stargate-backend/src/restClient.ts)
            const prettyError = _get(messagingLanguage, `en-us.${errorObj.statusText}`, generalError);
            dispatch(attemptLoginFailure({ message: `${prettyError}`}));
          });
        }
        else {
          console.log({error});
          // If our error isn't a json()-able object, just, uh, give the general error
          dispatch(attemptLoginFailure({ message: generalError}));
        }
      })
  }
);
