import Immutable from 'immutable';

import {
  TEST_API_REQUEST,
  TEST_API_SUCCESS,
  TEST_API_FAILURE,
 } from '../actions/testAPI';

const initialState = Immutable.fromJS({
  loading: false,
  message: 'Initial reducer message',
});

const testAPIReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST_API_REQUEST:
      return state.set('loading', true);
    case TEST_API_SUCCESS:
      return state
        .set('message', action.payload)
        .set('loading', false);
    case TEST_API_FAILURE:
      return state
        .set('message', 'There was a problem. Try again later.')
        .set('loading', false);
    default:
      return state
  }
}

export default testAPIReducer;
