import 'isomorphic-fetch';
import { createAction } from 'redux-actions';
import _get from 'lodash/get';

import { messagingLanguage } from './login.data';

export const ATTEMPT_PW_RESET_REQUEST = 'ATTEMPT_PW_RESET_REQUEST';
export const ATTEMPT_PW_RESET_SUCCESS = 'ATTEMPT_PW_RESET_SUCCESS';
export const ATTEMPT_PW_RESET_FAILURE = 'ATTEMPT_PW_RESET_FAILURE';

export const ATTEMPT_CHANGE_PASSWORD_REQUEST = 'ATTEMPT_CHANGE_PASSWORD_REQUEST';
export const ATTEMPT_CHANGE_PASSWORD_SUCCESS = 'ATTEMPT_CHANGE_PASSWORD_SUCCESS';
export const ATTEMPT_CHANGE_PASSWORD_FAILURE = 'ATTEMPT_CHANGE_PASSWORD_FAILURE';

export const attemptPasswordResetRequest = createAction(ATTEMPT_PW_RESET_REQUEST);
export const attemptPasswordResetSuccess = createAction(ATTEMPT_PW_RESET_SUCCESS);
export const attemptPasswordResetFailure = createAction(ATTEMPT_PW_RESET_FAILURE);

export const attemptChangePasswordRequest = createAction(ATTEMPT_CHANGE_PASSWORD_REQUEST);
export const attemptChangePasswordSuccess = createAction(ATTEMPT_CHANGE_PASSWORD_SUCCESS);
export const attemptChangePasswordFailure = createAction(ATTEMPT_CHANGE_PASSWORD_FAILURE);

export const attemptPasswordReset = (data = {}) => (
  (dispatch) => {
    dispatch(attemptPasswordResetRequest())
    return fetch(
      '/api/password/_reset',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then((res) => {
        if (!res.ok) throw Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (!data) throw Error(_get(messagingLanguage, `en-us.unknown-error`));

        dispatch(
          attemptPasswordResetSuccess(
            {
              message: _get(messagingLanguage, `en-us.password-reset-email-sent`),
            }
          )
        );
      })
      .catch((error) => {
        console.log(error);
        const prettyError = _get(messagingLanguage, `en-us.unknown-error`);
        dispatch(attemptPasswordResetFailure({ message: `${prettyError}`}));
      })
  }
);

export const attemptChangePassword = (data = {}) => (
  (dispatch) => {
    dispatch(attemptChangePasswordRequest())
    return fetch(
      '/api/password/_change',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then((data) => {
        return Promise.all([data, data.json()]);
      })
      .then(([response, json]) => {
        if (response.ok) {
          if (!json) throw Error(_get(messagingLanguage, `en-us.unknown-error`));

          dispatch(
            attemptChangePasswordSuccess()
          );
        }
        else {
          throw Error(json.statusText);
        }
      })
      .catch((error) => {
        const prettyError = _get(messagingLanguage, `en-us.${error}`, error);
        dispatch(attemptChangePasswordFailure({ message: `${prettyError}`}));
      })
  }
);
