import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import { EuiButton } from '@elastic/eui/lib/components/button/button';
import { EuiCallOut } from '@elastic/eui/lib/components/call_out';
import { EuiFieldText } from '@elastic/eui/lib/components/form/field_text';
import { EuiForm } from '@elastic/eui/lib/components/form/form';
import { EuiFormRow } from '@elastic/eui/lib/components/form/form_row';

import { attemptPasswordReset } from '../../actions/resetPassword';
import { clearErrors } from '../../actions/clearErrors';

export class RequestResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      fieldErrors: {
        email: false,
      },
    }

    // Bind function(s)
    this.handleRequestEmail = this.handleRequestEmail.bind(this);
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  handleRequestEmail(event) {
    event.preventDefault();

    if (this.validateForm()) {
      this.props.attemptPasswordReset({
        username: this.state.email,
      });
    }
  }

  onFieldChange = event => {
    let target = event.target.name;

    // These lines ensure that, when a user begins changing a field currently
    // in an error state, we remove said error state
    const fieldErrors = this.state.fieldErrors;
    fieldErrors[target] = false;
    this.setState({
      fieldErrors: fieldErrors,
    });

    // Additionally, sync component state's value with the field value
    this.setState({
      [target]: event.target.value
    });
  }

  validateForm() {
    let formIsInvalid = false;
    const fieldErrors = {};

    // Simple, trustworthy front end validation
    if (_isEmpty(this.state.email)) {
      formIsInvalid = true;
      fieldErrors['email'] = 'Email is required';
    }

    if (formIsInvalid) {
      this.setState({ fieldErrors: fieldErrors });
      return false;
    }

    return true;
  }

  render() {
    const isLoading = !!this.props.loading;

    if (this.props.message && !isLoading) {
      return (
        <React.Fragment>
          <EuiCallOut>
            <p>{ this.props.message }</p>
          </EuiCallOut>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          <EuiForm>
            <form onSubmit={this.handleRequestEmail}>
              <EuiFormRow
                error={this.state.fieldErrors.email || undefined}
                isInvalid={!!this.state.fieldErrors.email}
                label="Email">
                <EuiFieldText
                  autoFocus
                  name="email"
                  onChange={this.onFieldChange}
                  placeholder="Enter email address"
                  type="email"
                />
              </EuiFormRow>
              <br />
              <EuiButton
                fill
                isLoading={isLoading}
                type="submit"
              >
                Request reset link
              </EuiButton>
            </form>
          </EuiForm>
        </React.Fragment>
      )
    }
  }
}

RequestResetPasswordContainer.propTypes = {
  attemptPasswordReset: PropTypes.func,
  clearErrors: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.string,
};

RequestResetPasswordContainer.defaultProps = {
  attemptPasswordReset: () => {},
  clearErrors: () => {},
  history: {},
  loading: false,
  message: null,
};

const mapStateToProps = state => ({
  loading: state.userOperations.get('loading'),
  message: state.userOperations.get('message'),
});

const mapDispatchToProps = dispatch => ({
  attemptPasswordReset: (data) => {
    dispatch(attemptPasswordReset(data))
  },
  clearErrors: () => { dispatch(clearErrors()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestResetPasswordContainer);
