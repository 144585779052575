import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EuiButton } from '@elastic/eui/lib/components/button/button';
import { EuiCallOut } from '@elastic/eui/lib/components/call_out';
import { EuiFieldPassword } from '@elastic/eui/lib/components/form/field_password';
import { EuiFieldText } from '@elastic/eui/lib/components/form/field_text';
import { EuiForm } from '@elastic/eui/lib/components/form/form';
import { EuiFormRow } from '@elastic/eui/lib/components/form/form_row';

import { attemptLogin } from '../../actions/login';
import { checkForSession } from '../../actions/session';
import { clearErrors } from '../../actions/clearErrors';

export class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    // `destination` is the informal name of the app the user is trying to
    // access (/login/$destination/). It must match up to its object key within
    // the `../actions/login.data.js` file for it to be of any use.
    const destination = _get(props.match, 'params.destination', null);

    this.state = {
      destination,
      email: '',
      fieldErrors: {
        email: false,
        password: false,
      },
      password: '',
      redirectURL: null,
    };

    // Bind function(s)
    this.handleLogin = this.handleLogin.bind(this);
  }

  // If the user has an active session, this will flip the `redirect` prop
  // to true and send them on their way to whichever app they're trying to
  // login to
  componentDidMount() {
    this.props.checkForSession(this.state.destination);
  }

  componentDidUpdate(prevProps) {
    // If we receive new props telling us to redirect, let's setState() with that
    // data, so the render() method can pick it up and do good things with it
    if (!prevProps.redirect && this.props.redirect) {
      // A barebones find-and-replace function. Can be expanded upon if needbe.
      const replacedURL = this.props.redirectURL.replace(
        '__TOKEN__',
        this.props.token
      );
      this.setState({ redirectURL: replacedURL });
    }
    return null;
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }

  handleLogin(event) {
    event.preventDefault();

    if (this.validateForm()) {
      this.props.attemptLogin(
        {
          username: this.state.email,
          password: this.state.password,
        },
        this.state.destination
      );
    }
  }

  onFieldChange = (event) => {
    let target = event.target.name;

    // These lines ensure that, when a user begins changing a field currently
    // in an error state, we remove said error state
    const fieldErrors = this.state.fieldErrors;
    fieldErrors[target] = false;
    this.setState({
      fieldErrors: fieldErrors,
    });

    // Additionally, sync component state's value with the field value
    this.setState({
      [target]: event.target.value,
    });
  };

  validateForm() {
    let formIsInvalid = false;
    const fieldErrors = {};

    // Simple, trustworthy front end validation
    if (_isEmpty(this.state.email)) {
      formIsInvalid = true;
      fieldErrors['email'] = 'Email is required';
    }
    if (_isEmpty(this.state.password)) {
      formIsInvalid = true;
      fieldErrors['password'] = 'Password is required';
    }

    if (formIsInvalid) {
      this.setState({ fieldErrors: fieldErrors });
      return false;
    }

    return true;
  }

  renderRegistrationLink() {
    if (this.state.destination === 'partner') {
      return (
        <a href="https://partners.elastic.co/English/register_email.aspx">
          Register
        </a>
      );
    }
    return (
      <Link to={`/register/${this.state.destination}`}>Create an account</Link>
    );
  }

  render() {
    const isLoading = !!this.props.loading || !!this.state.redirectURL;

    if (this.state.redirectURL) {
      window.location = this.state.redirectURL;
    }

    // Note: All props can be edited by the client, and are therefore insecure.
    // Users can see all of this code if they wish. All private data must be loaded
    // secure endpoints (we use tokens to do this).
    if (this.props.loggedIn && !isLoading) {
      return (
        <div className="euiCard">
          Success! You’ve authed.
          <br />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {this.props.errorMessage && !isLoading && (
            <React.Fragment>
              <EuiCallOut title="Login error" color="warning" iconType="alert">
                <p>{`${this.props.errorMessage}`}</p>
              </EuiCallOut>
              <div className={classNames('euiSpacer', 'euiSpacer--m')} />
            </React.Fragment>
          )}
          <EuiForm>
            <form onSubmit={this.handleLogin}>
              <EuiFormRow
                error={this.state.fieldErrors.email || undefined}
                isInvalid={!!this.state.fieldErrors.email}
                label="Email"
              >
                <EuiFieldText
                  autoFocus
                  name="email"
                  onChange={this.onFieldChange}
                  placeholder="Enter email address"
                  type="email"
                />
              </EuiFormRow>
              <EuiFormRow
                error={this.state.fieldErrors.password || undefined}
                isInvalid={!!this.state.fieldErrors.password}
                label="Password"
              >
                <EuiFieldPassword
                  name="password"
                  onChange={this.onFieldChange}
                  placeholder="Enter password"
                />
              </EuiFormRow>
              <br />
              <EuiButton fill isLoading={isLoading} type="submit">
                Log in
              </EuiButton>
            </form>
          </EuiForm>
          <br />
          <div
            className={classNames(
              'euiFlexGroup',
              'euiFlexGroup--justifyContentSpaceBetween',
              'euiFlexGroup--directionRow',
              'euiText',
              'euiText--small'
            )}
          >
            <div
              className={classNames('euiFlexItem', 'euiFlexItem--flexGrowZero')}
            >
              <Link to="/reset-password">Forgot password?</Link>
            </div>
            <div
              className={classNames('euiFlexItem', 'euiFlexItem--flexGrowZero')}
            >
              {this.renderRegistrationLink()}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

LoginContainer.propTypes = {
  attemptLogin: PropTypes.func,
  checkForSession: PropTypes.func,
  clearErrors: PropTypes.func,
  loggedIn: PropTypes.bool,
  errorMessage: PropTypes.string,
  match: PropTypes.object,
  redirect: PropTypes.bool,
  redirectURL: PropTypes.string,
  token: PropTypes.string,
};

LoginContainer.defaultProps = {
  attemptLogin: () => {},
  checkForSession: () => {},
  clearErrors: () => {},
  loggedIn: false,
  errorMessage: 'Store not loaded',
  match: {},
  redirect: false,
  redirectURL: null,
  token: null,
};

const mapStateToProps = (state) => ({
  loading: state.login.get('loading'),
  loggedIn: state.login.get('loggedIn'),
  errorMessage: state.login.get('message'),
  redirect: state.login.get('redirect'),
  redirectURL: state.login.get('redirectURL'),
  token: state.login.get('token'),
});

const mapDispatchToProps = (dispatch) => ({
  attemptLogin: (data, destination) => {
    dispatch(attemptLogin(data, destination));
  },
  checkForSession: (destination) => {
    dispatch(checkForSession(destination));
  },
  clearErrors: () => {
    dispatch(clearErrors());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
