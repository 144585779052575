import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import 'url-search-params-polyfill';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { EuiCallOut } from '@elastic/eui/lib/components/call_out';
import { EuiLoadingSpinner } from '@elastic/eui/lib/components/loading/loading_spinner';

import { UpdatePasswordFields } from '../../components/UpdatePasswordFields';
import { attemptChangePassword } from '../../actions/resetPassword';
import { attemptVerifyActivationToken, attemptVerifyPasswordToken } from '../../actions/verifyToken';

export class ValidateTokenContainer extends React.Component {
  constructor(props) {
    super(props);

    // userToken is generated and verified by Okta. it's sent to the user's
    // email address as the end of a URL pointing here (e.g., /reset-password/${token}).
    // It could technically be a recovery token, or an activation token.
    const userToken = _get(props.match, 'params.token', null);
    const queryParams = new URLSearchParams(_get(props.location, 'search', null));
    const isPartner = !!queryParams.get('partner');

    this.state = {
      userToken,
      isPartner
    }

    // Bind function(s)
    this.handleVerifyToken = this.handleVerifyToken.bind(this);
  }

  componentDidMount() {
    this.handleVerifyToken();
  }

  handleVerifyToken() {
    if (_isEmpty(this.state.userToken)) return false;

    // We have slightly different calls (and responses) based on whether
    // this is an account activation or a password reset
    if (this.props.activation) {
      this.props.attemptVerifyActivationToken({
        token: this.state.userToken
      });
    } else {
      this.props.attemptVerifyPasswordToken({
        recoveryToken: this.state.userToken
      });
    }
  }

  render() {
    const isLoading = !!this.props.loading;

    if (_isEmpty(this.state.userToken)) {
      return window.location = '/password-reset';
    }

    // `userTokenVerified` can be used as a boolean shorthand for whether
    // to display UpdatePasswordFields or not (we only display them after
    // the token has been verified). E.g., we don't want to return() yet
    // if the token has been verified, because we need to show them the
    // password fields alongside the error (might be a 'password too short'
    // error).
    if (this.props.errorMessage && !this.props.userTokenVerified) {
      return(
          <EuiCallOut
            title="Error"
            color="warning"
            iconType="alert"
          >
            <p>{ `${this.props.errorMessage}` }</p>
          </EuiCallOut>
      );
    }

    // Loading spinner is better than a blank screen! And if we don't have
    // a stateToken or an account activation, we've got nothing to show.
    if (_isEmpty(this.props.stateToken) && !this.props.accountActivated) {
      return (
        <div className={classNames('euiTextAlign', 'euiTextAlign--center')}>
          <EuiLoadingSpinner />
          <br />
          { isLoading ? <p>Verifying your link</p> : null }
        </div>
      );
    }

    // Note that the following states lead all users to the partner portal
    // login. This is a temporary solution during the period of time when
    // partner portal is the only app using stargate, and the user migrations
    // are still happening. After either of these periods expire, we'll
    // need to update this to be smarter about where to link users.
    if (this.props.accountActivated) {
      return (
        <EuiCallOut title="Success">
          <p>
            Account verified! You can <Link to="/login/partner">log in now.</Link>
          </p>
        </EuiCallOut>
      )
    }

    // Same messaging logic as above. Eventually, we should get smarter about
    // where to lead a user after these actions.
    if (this.props.passwordHasChanged) {
      if (this.state.isPartner) {
        return (
          <EuiCallOut title="Success">
            <p>
              Your password has been set. <Link to="/login/partner">Log in now.</Link>
            </p>
          </EuiCallOut>
        )
      }
      return (
        <EuiCallOut title="Success">
          <p>
            Password updated successfully. You can <Link to="/login/partner">log in now.</Link>
          </p>
        </EuiCallOut>
      )
    }

    return (
      <React.Fragment>
        { this.props.errorMessage &&
          <React.Fragment>
            {
              <EuiCallOut
                title="Error"
                color="warning"
                iconType="alert"
              >
                <p>{ this.props.errorMessage }</p>
              </EuiCallOut>
            }
            <div className={classNames('euiSpacer', 'euiSpacer--m')}></div>
          </React.Fragment>
        }
        <UpdatePasswordFields
          attemptChangePassword={this.props.attemptChangePassword}
          isLoading={isLoading}
          stateToken={this.props.stateToken}
        />
      </React.Fragment>
    )
  }
}

ValidateTokenContainer.propTypes = {
  accountActivated: PropTypes.bool,
  activation: PropTypes.bool,
  attemptChangePassword: PropTypes.func,
  attemptVerifyActivationToken: PropTypes.func,
  attemptVerifyPasswordToken: PropTypes.func,
  errorMessage: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  passwordHasChanged: PropTypes.bool,
  userTokenVerified: PropTypes.bool,
  stateToken: PropTypes.string
};

ValidateTokenContainer.defaultProps = {
  accountActivated: false,
  activation: false,
  attemptChangePassword: () => {},
  attemptVerifyActivationToken: () => {},
  attemptVerifyPasswordToken: () => {},
  errorMessage: '',
  location: {},
  match: {},
  passwordHasChanged: false,
  userTokenVerified: false,
  stateToken: null
};

const mapStateToProps = state => ({
  accountActivated: state.userOperations.get('accountActivated'),
  errorMessage: state.userOperations.get('errorMessage'),
  loading: state.userOperations.get('loading'),
  passwordHasChanged: state.userOperations.get('passwordHasChanged'),
  userTokenVerified: state.userOperations.get('userTokenVerified'),
  stateToken: state.userOperations.get('stateToken')
});

const mapDispatchToProps = dispatch => ({
  attemptChangePassword: (data) => { dispatch(attemptChangePassword(data)) },
  attemptVerifyActivationToken: (data) => { dispatch(attemptVerifyActivationToken(data)) },
  attemptVerifyPasswordToken: (data) => { dispatch(attemptVerifyPasswordToken(data)) }
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateTokenContainer);
