import React from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { EuiButton } from '@elastic/eui/lib/components/button/button';
import { EuiForm } from '@elastic/eui/lib/components/form/form';
import { EuiFormRow } from '@elastic/eui/lib/components/form/form_row';
import { EuiFieldPassword } from '@elastic/eui/lib/components/form/field_password';

export class UpdatePasswordFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldErrors: {
        password1: false,
        password2: false
      },
      password1: '',
      password2: '',
    }

    // Bind function(s)
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  handleChangePassword(event) {
    event.preventDefault();

    if (this.validateForm()) {
      this.props.attemptChangePassword({
        stateToken: this.props.stateToken,
        newPassword: this.state.password1
      });
    }
  }

  onFieldChange = event => {
    let target = event.target.name;

    // These lines ensure that, when a user begins changing a field currently
    // in an error state, we remove said error state
    const fieldErrors = this.state.fieldErrors;
    fieldErrors[target] = false;
    this.setState({
      fieldErrors: fieldErrors,
    });

    // Additionally, sync component state's value with the field value
    this.setState({
      [target]: event.target.value
    });
  }

  validateForm() {
    let formIsInvalid = false;
    const fieldErrors = {};

    // Simple, trustworthy front end validation
    if (_isEmpty(this.state.password1)) {
      formIsInvalid = true;
      fieldErrors['password1'] = 'Password is required';
    }
    if (_isEmpty(this.state.password2)) {
      formIsInvalid = true;
      fieldErrors['password2'] = 'Retype your password';
    }
    if (this.state.password1 !== this.state.password2) {
      formIsInvalid = true;
      fieldErrors['password2'] = 'Password does not match';
    }
    if (this.state.password1.length < 8) {
      formIsInvalid = true;
      fieldErrors['password1'] = 'Password must be 8+ characters long';
    }

    if (formIsInvalid) {
      this.setState({ fieldErrors: fieldErrors });
      return false;
    }

    return true;
  }

  render() {
    return (
      <EuiForm> 
        <form
          autoComplete="off"
          onSubmit={this.handleChangePassword}
        >
          <EuiFormRow
            error={this.state.fieldErrors.password1 || undefined}
            isInvalid={!!this.state.fieldErrors.password1}
            label="new password">
            <EuiFieldPassword
              autoFocus
              autoComplete="off"
              name="password1"
              onChange={this.onFieldChange}
              placeholder="Enter password"
            />
          </EuiFormRow>
          <EuiFormRow
            error={this.state.fieldErrors.password2 || undefined}
            isInvalid={!!this.state.fieldErrors.password2}
            label="retype password"
          >
            <EuiFieldPassword
              autoComplete="off"
              name="password2"
              onChange={this.onFieldChange}
              placeholder="Enter password again"
            />
          </EuiFormRow>
          <br />
          <EuiButton
            fill
            isLoading={this.props.isLoading}
            type="submit"
          >
            Change password
          </EuiButton>
        </form>
      </EuiForm>
    )
  }
}

UpdatePasswordFields.propTypes = {
  attemptChangePassword: PropTypes.func,
  isLoading: PropTypes.bool,
  stateToken: PropTypes.string,
};

UpdatePasswordFields.defaultProps = {
  attemptChangePassword: () => {},
  isLoading: false,
  stateToken: null,
};

export default UpdatePasswordFields;
