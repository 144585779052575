import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { EuiButton } from '@elastic/eui/lib/components/button/button';

import { testAPI } from '../../actions/testAPI';

export class ApiTestButton extends React.Component {
  constructor(props) {
    super(props);

    // Bind functions
    this.handleTest = this.handleTest.bind(this);
  }
  
  handleTest() {
    // Front-end validation goes here, eventually
    this.props.testAPI();
  }

  render() {
    const isLoading = !!this.props.loading;
    return (
      <div className="euiCard">
        <p>{ isLoading ? 'Loading...' : this.props.testMessage }</p>
        <br />
        <EuiButton
          isLoading={isLoading}
          onClick={this.handleTest}
        >
          Test Local API
        </EuiButton>
      </div>
    )
  }
}

ApiTestButton.propTypes = {
  testAPI: PropTypes.func,
  testMessage: PropTypes.string
};

ApiTestButton.defaultProps = {
  testAPI: () => {},
  testMessage: "Store not loaded"
};

const mapStateToProps = state => ({
  loading: state.testAPI.get('loading'),
  testMessage: state.testAPI.get('message')
});

const mapDispatchToProps = dispatch => ({
  testAPI: () => { dispatch(testAPI()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTestButton);
