import React from 'react';
import { Link } from 'react-router-dom';

import { EuiCallOut } from '@elastic/eui/lib/components/call_out';

const AccessDenied = () => (
  <EuiCallOut
    title="Access Denied"
    color="warning"
    iconType="user"
  >
    <p>
      You don’t have access to this app yet. Please check for your verification email. If you didn’t receive one, please <a href="mailto:partnerportal@elastic.co">contact support</a>, or you can try <Link to="/reset-password">resetting your password</Link> to verify email ownership.
    </p>
  </EuiCallOut>
);

export default AccessDenied;
