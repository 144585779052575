import 'isomorphic-fetch';
import { createAction } from 'redux-actions';

export const TEST_API_REQUEST = 'TEST_API_REQUEST';
export const TEST_API_SUCCESS = 'TEST_API_SUCCESS';
export const TEST_API_FAILURE = 'TEST_API_FAILURE';

export const testAPIRequest = createAction(TEST_API_REQUEST);
export const testAPISuccess = createAction(TEST_API_SUCCESS);
export const testAPIFailure = createAction(TEST_API_FAILURE);

export const testAPI = () => (
  (dispatch) => {
    dispatch(testAPIRequest())
    return fetch('/api/delay', { method: 'GET' })
      .then((res) => {
        if (!res.ok) throw Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (!data.delay) throw Error('No message received');
        dispatch(testAPISuccess(`Success: ${data.delay}ms delay`));
      })
      .catch((error) => {
        console.log(error);
        dispatch(testAPIFailure());
      })
  }
);
