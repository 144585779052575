/* First line needs to be the polyfill. We're supporting IE11 here. See:
 * https://github.com/facebook/create-react-app/[...]/packages/react-app-polyfill
 */
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore
} from 'redux';
import thunkMiddleware from 'redux-thunk';

import App from './App';
import loginReducer from './reducers/login';
import testAPIReducer from './reducers/testAPI';
import userOperationsReducer from './reducers/userOperations';

const isProd = process.env.NODE_ENV === "production" ? true : false;

const composeEnhancers =
  (isProd ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(combineReducers({
  login: loginReducer,
  testAPI: testAPIReducer,
  userOperations: userOperationsReducer,
 }),
  composeEnhancers(applyMiddleware(thunkMiddleware)));

const wrapApp = (AppRouter, reduxStore) => (
  <Provider store={reduxStore}>
    <AppRouter />
  </Provider>
);

ReactDOM.render(wrapApp(App, store), document.getElementById('root'));
