import 'isomorphic-fetch';
import { createAction } from 'redux-actions';
import _get from 'lodash/get';

import { messagingLanguage } from './login.data';

export const ATTEMPT_VERIFY_ACTIVATION_TOKEN_REQUEST = 'ATTEMPT_VERIFY_ACTIVATION_TOKEN_REQUEST';
export const ATTEMPT_VERIFY_ACTIVATION_TOKEN_SUCCESS = 'ATTEMPT_VERIFY_ACTIVATION_TOKEN_SUCCESS';
export const ATTEMPT_VERIFY_ACTIVATION_TOKEN_FAILURE = 'ATTEMPT_VERIFY_ACTIVATION_TOKEN_FAILURE';

export const ATTEMPT_VERIFY_PW_TOKEN_REQUEST = 'ATTEMPT_VERIFY_PW_TOKEN_REQUEST';
export const ATTEMPT_VERIFY_PW_TOKEN_SUCCESS = 'ATTEMPT_VERIFY_PW_TOKEN_SUCCESS';
export const ATTEMPT_VERIFY_PW_TOKEN_FAILURE = 'ATTEMPT_VERIFY_PW_TOKEN_FAILURE';

export const attemptVerifyActivationTokenRequest = createAction(ATTEMPT_VERIFY_ACTIVATION_TOKEN_REQUEST);
export const attemptVerifyActivationTokenSuccess = createAction(ATTEMPT_VERIFY_ACTIVATION_TOKEN_SUCCESS);
export const attemptVerifyActivationTokenFailure = createAction(ATTEMPT_VERIFY_ACTIVATION_TOKEN_FAILURE);

export const attemptVerifyPasswordTokenRequest = createAction(ATTEMPT_VERIFY_PW_TOKEN_REQUEST);
export const attemptVerifyPasswordTokenSuccess = createAction(ATTEMPT_VERIFY_PW_TOKEN_SUCCESS);
export const attemptVerifyPasswordTokenFailure = createAction(ATTEMPT_VERIFY_PW_TOKEN_FAILURE);

export const attemptVerifyActivationToken = (data = {}) => (
  (dispatch) => {
    dispatch(attemptVerifyActivationTokenRequest())
    return fetch(
      '/api/activation/_verify-token',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then((res) => {
        if (!res.ok) throw Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (!data) throw Error(_get(messagingLanguage, `en-us.unknown-error`));

        let params = {
          accountActivated: true,
          stateToken: null
        };

        if (data.recoveryType === 'ACCOUNT_ACTIVATION' || data.recoveryType === 'PASSWORD') {
          params.accountActivated = false;
          params.stateToken = data.stateToken;
        }

        dispatch(attemptVerifyActivationTokenSuccess(params));
      })
      .catch((error) => {
        console.log(error);
        const prettyError = _get(messagingLanguage, `en-us.activate-token-error`);
        dispatch(attemptVerifyActivationTokenFailure({ message: `${prettyError}`}));
      })
  }
);

export const attemptVerifyPasswordToken = (data = {}) => (
  (dispatch) => {
    dispatch(attemptVerifyPasswordTokenRequest())
    return fetch(
      '/api/password/_verify-token',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then((res) => {
        if (!res.ok) throw Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (!data) throw Error(_get(messagingLanguage, `en-us.unknown-error`));

        dispatch(
          attemptVerifyPasswordTokenSuccess({
            stateToken: data.stateToken
          })
        );
      })
      .catch((error) => {
        console.log(error);
        const prettyError = _get(messagingLanguage, `en-us.password-reset-token-error`);
        dispatch(attemptVerifyPasswordTokenFailure({ message: `${prettyError}`}));
      })
  }
);
