const lmsAppURL = process.env.LMS_APP_URL || 'https://auth-sandbox.elastic.co/home/elasticcoexternal_docebo_1/0oaigtmdaakHDxBqg0h7/alnigv9ot6UeewpEC0h7';
const checkoutAppURL = process.env.CHECKOUT_APP_URL || 'https://auth-sandbox.elastic.co/home/elasticcoexternal_suitecommerce_1/0oaake7fqeyPHkjiH0h7/alnake8ven2iMx0CK0h7';
const partnerAppURL = process.env.PRM_APP_URL || 'https://auth-sandbox.elastic.co/home/elasticcoexternal_impartnerpoc_1/0oaix3bi6j0SRro4R0h7/alnix3gf6id37OCW70h7';


/*
 * See: https://developer.okta.com/use_cases/authentication/session_cookie#retrieving-a-session-cookie-by-visiting-an-application-embed-link
*/
export const loginConfigs = {
  learn: {
    redirectURL: `${lmsAppURL}?sessionToken=__TOKEN__`
  },
  cloud: {
    redirectURL: null
  },
  checkout: {
    redirectURL: `${checkoutAppURL}?sessionToken=__TOKEN__`
  },
  partner: {
    redirectURL: `${partnerAppURL}?sessionToken=__TOKEN__`
  },
};

/*
 * Translate errors & statuses into nicer language. Note the first layer is a locale;
 * eventually this can be leveraged for internationalization
*/
export const messagingLanguage = {
  'en-us': {
    'Authentication failed': 'Incorrect user/password combination',
    'unknown-error': 'Please try again later',
    'password-reset-email-sent': 'Thank you! We will send password reset instructions if the email address is associated with an open account.',
    'password-reset-token-error': 'This password reset link is invalid, or has expired.',
    'activate-token-error': 'Your activation link is invalid, or has expired.',
    'Error: login: An object with this field already exists in the current organization': 'There is an existing account associated with your email address.',
    'Error: login: Username must be in the form of an email address': 'Please ensure your email address is valid.',
  }
}
