import Immutable from 'immutable';

import {
  CLEAR_ERRORS
} from '../actions/clearErrors';

import {
  ATTEMPT_PW_RESET_REQUEST,
  ATTEMPT_PW_RESET_SUCCESS,
  ATTEMPT_PW_RESET_FAILURE,
  ATTEMPT_CHANGE_PASSWORD_REQUEST,
  ATTEMPT_CHANGE_PASSWORD_SUCCESS,
  ATTEMPT_CHANGE_PASSWORD_FAILURE,
 } from '../actions/resetPassword';

import {
  ATTEMPT_VERIFY_ACTIVATION_TOKEN_REQUEST,
  ATTEMPT_VERIFY_ACTIVATION_TOKEN_SUCCESS,
  ATTEMPT_VERIFY_ACTIVATION_TOKEN_FAILURE,
  ATTEMPT_VERIFY_PW_TOKEN_REQUEST,
  ATTEMPT_VERIFY_PW_TOKEN_SUCCESS,
  ATTEMPT_VERIFY_PW_TOKEN_FAILURE,
 } from '../actions/verifyToken';

const initialState = Immutable.fromJS({
  accountActivated: false,
  errorMessage: '',
  loading: false,
  message: '',
  passwordHasChanged: false,
  userTokenVerified: false,
  stateToken: null,
});

const userOperationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTEMPT_PW_RESET_REQUEST:
      return state
        .set('loading', true)
        .set('message', '');
    case ATTEMPT_PW_RESET_SUCCESS:
      return state
        .set('message', action.payload.message)
        .set('loading', false);
    case ATTEMPT_PW_RESET_FAILURE:
      return state
        .set('message', action.payload.message)
        .set('loading', false);

    case ATTEMPT_VERIFY_ACTIVATION_TOKEN_REQUEST:
      return state
      .set('loading', true)
      .set('errorMessage', '')
      .set('message', '');
    case ATTEMPT_VERIFY_ACTIVATION_TOKEN_SUCCESS:
      return state
      .set('loading', false)
      .set('userTokenVerified', true)
      .set('stateToken', action.payload.stateToken)
      .set('accountActivated', action.payload.accountActivated);
    case ATTEMPT_VERIFY_ACTIVATION_TOKEN_FAILURE:
      return state
      .set('loading', false)
      .set('errorMessage', action.payload.message);

    case ATTEMPT_VERIFY_PW_TOKEN_REQUEST:
      return state
      .set('loading', true)
      .set('errorMessage', '')
      .set('message', '');
    case ATTEMPT_VERIFY_PW_TOKEN_SUCCESS:
      return state
      .set('loading', false)
      .set('userTokenVerified', true)
      .set('stateToken', action.payload.stateToken);
    case ATTEMPT_VERIFY_PW_TOKEN_FAILURE:
      return state
      .set('loading', false)
      .set('errorMessage', action.payload.message);

    case ATTEMPT_CHANGE_PASSWORD_REQUEST:
      return state
      .set('loading', true)
      .set('errorMessage', '')
      .set('message', '');
    case ATTEMPT_CHANGE_PASSWORD_SUCCESS:
      return state
      .set('loading', false)
      .set('passwordHasChanged', true);
    case ATTEMPT_CHANGE_PASSWORD_FAILURE:
      return state
      .set('loading', false)
      .set('errorMessage', action.payload.message);

    case CLEAR_ERRORS:
      return state
        .set('errorMessage', '')
        .set('message', '');

    default:
      return state
  }
};

export default userOperationsReducer;
