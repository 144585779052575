import { createAction } from 'redux-actions';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const clearErrorAction = createAction(CLEAR_ERRORS);

export const clearErrors = () => (
  (dispatch) => {
    dispatch(clearErrorAction());
  }
);
