import 'isomorphic-fetch';
import { createAction } from 'redux-actions';
import _get from 'lodash/get';

import { loginConfigs } from './login.data';

export const CHECK_FOR_SESSION_REQUEST = 'CHECK_FOR_SESSION_REQUEST';
export const CHECK_FOR_SESSION_TRUE = 'CHECK_FOR_SESSION_TRUE';
export const CHECK_FOR_SESSION_FALSE = 'CHECK_FOR_SESSION_FALSE';

export const checkForSessionRequest = createAction(CHECK_FOR_SESSION_REQUEST);
export const checkForSessionTrue = createAction(CHECK_FOR_SESSION_TRUE);
export const checkForSessionFalse = createAction(CHECK_FOR_SESSION_FALSE);

const sessionDomain = process.env.SESSION_DOMAIN || 'https://auth-sandbox.elastic.co';

/* If a user is logging in (or registering), we want to first confirm that they
 * have no active sessions with Okta. If they do, we need to forward them to
 * whichever app they were requesting, so that they can log out if they want to
 * log back in with a different user. This call is unique because it isn't a
 * call to our backend; it's from the user's browser directly to Okta, cookies
 * included, to get their session status. See: 
 * https://developer.okta.com/docs/api/resources/sessions#get-current-session
 */
export const checkForSession = (destination = null) => (
  (dispatch) => {
    const url = `${sessionDomain}/api/v1/sessions/me`;

    dispatch(checkForSessionRequest());
    return fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }
    )
      .then((res) => {
        if (!res.ok) throw Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (!data) throw Error();

        // If they're requesting a known destination (in our loginConfig), send the redirectURL
        // along. If not, it'll be null, and the app will know how to handle that.
        const destinationURL = _get(loginConfigs, `${destination}.redirectURL`);
        
        dispatch(
          checkForSessionTrue(
            {
              redirect: !!destinationURL,
              redirectURL: destinationURL
            }
          )
        );
      })
      .catch((error) => {
        // Note that we don't actually do anything if this session call fails: failure and
        // "not having a session" are synonymous, so we can just let things continue.
        dispatch(checkForSessionFalse());
      })
  }
);
