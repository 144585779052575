import React from 'react';

export const titleData = {
  '^/login.*$': (
    <h1 className="page-title">
      Log In
      <br />
      <span className="page-subtitle">to the Elastic Partner Portal</span>
    </h1>
  ),
  '^/register.*$': <h1 className="page-title">Create Account</h1>,
  '^/reset-password.*$': <h1 className="page-title">Reset Password</h1>,
};
