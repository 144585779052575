import Immutable from 'immutable';

import {
  CLEAR_ERRORS
} from '../actions/clearErrors';

import {
  ATTEMPT_LOGIN_REQUEST,
  ATTEMPT_LOGIN_SUCCESS,
  ATTEMPT_LOGIN_FAILURE,
 } from '../actions/login';

import {
  CHECK_FOR_SESSION_REQUEST,
  CHECK_FOR_SESSION_TRUE,
  CHECK_FOR_SESSION_FALSE,
} from '../actions/session';

const initialState = Immutable.fromJS({
  error: false,
  loading: false,
  loggedIn: false,
  message: '',
  redirect: false,
  redirectURL: null,
  token: null
});

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTEMPT_LOGIN_REQUEST:
      return state
        .set('error', false)
        .set('loading', true)
        .set('message', '');
    case ATTEMPT_LOGIN_SUCCESS:
      return state
        .set('error', false)
        .set('message', action.payload.message)
        .set('redirect', action.payload.redirect)
        .set('redirectURL', action.payload.redirectURL)
        .set('token', action.payload.token)
        .set('loggedIn', true)
        .set('loading', false);
    case ATTEMPT_LOGIN_FAILURE:
      return state
        .set('error', true)
        .set('message', action.payload.message)
        .set('loading', false);
    
    case CHECK_FOR_SESSION_REQUEST:
      return state
        .set('loading', true);
    case CHECK_FOR_SESSION_TRUE:
      return state
        .set('loading', false)
        .set('redirect', action.payload.redirect)
        .set('redirectURL', action.payload.redirectURL);
    case CHECK_FOR_SESSION_FALSE:
      return state
        .set('loading', false);

    case CLEAR_ERRORS:
      return state
        .set('error', false)
        .set('message', '');

    default:
      return state
  }
}

export default loginReducer;
